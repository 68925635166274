import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import TextArea from '../../../../components/TextArea';
import { createReview } from '../../../../stores/slices/reviewSlice';
import { setToastShow } from '../../../../stores/slices/toastSlice';
import useReviewForm from '../../index.hooks';

function ActionModal(props) {
  const dispatch = useDispatch();
  const {
    modal, onCloseModal, onSave, item, initForm, initTouched,
  } = props;
  const { reviewLoading } = useSelector((state) => state.review);
  const {
    form, touched, setForm, setTouched, handleOnBlur, handleFormChange, validateForm,
  } = useReviewForm();

  useEffect(() => {
    setForm(initForm);
    setTouched(initTouched);
  }, [initForm]);

  const onSaveForm = () => {
    if (validateForm()) {
      dispatch(createReview({ order_detail_id: item, ...form }))
        .unwrap()
        .then(() => {
          dispatch(
            setToastShow({
              toastShow: true,
              toastIsError: false,
              toastMessage: 'successfully create review',
            }),
          );
          return onSave(true);
        })
        .catch((err) => {
          dispatch(
            setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
          );
          return onSave(false);
        });
    }
  };

  const handleClick = (value) => {
    setForm((prevState) => ({ ...prevState, rating: value }));
  };

  const handleMouseOver = (value) => {
    setForm((prevState) => ({ ...prevState, hover: value }));
  };

  const handleMouseLeave = () => {
    setForm((prevState) => ({ ...prevState, hover: 1 }));
  };

  return (
    <Modal show={modal}>
      <div className="relative p-4 w-full max-w-md h-full mx-auto my-auto md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
            onClick={onCloseModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6">
            <h3 className="mb-5 text-center text-lg font-normal text-gray-500 dark:text-gray-400 capitalize">
              Create Review
            </h3>
            <div className="flex justify-center">
              {[...Array(5).keys()].map((e, i) => (
                <svg
                  key={`${item}-star-${e}`}
                  aria-hidden="true"
                  className={`${(form.hover > i || form.rating > i) ? 'text-yellow-300' : 'text-gray-300'} w-10 h-10`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => { handleClick(i + 1); }}
                  onMouseOver={() => { handleMouseOver(i + 1); }}
                  onMouseLeave={handleMouseLeave}
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              ))}
            </div>
            <TextArea
              id="description"
              type="description"
              name="description"
              touched={touched.description}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              value={form.description}
              label="Description"
              placeholder="description"
            />
            <div className="flex justify-center mt-4">
              <Button
                name="saveModal"
                className="text-white bg-red-600 hover:bg-red-800"
                isLoading={reviewLoading}
                isDisabled={reviewLoading}
                handleClick={onSaveForm}
              >
                Submit Review
              </Button>

              <Button
                name="closeModal"
                className="text-gray-500 bg-white hover:bg-gray-100"
                handleClick={onCloseModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ActionModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initForm: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
  ).isRequired,
  initTouched: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  item: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

export default ActionModal;
