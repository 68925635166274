import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartModalShow: false,
};

export const cartModalSlice = createSlice({
  name: 'cartModal',
  initialState,
  reducers: {
    setCartModal: (state) => ({ ...state, cartModalShow: !state.cartModalShow }),
  },
});

export const { setCartModal } = cartModalSlice.actions;

export default cartModalSlice.reducer;
