const questions = [
  {
    question: 'What is the only food that can never go bad?',
    answers: [
      { text: 'Honey', correct: true },
      { text: 'Pizza', correct: false },
      { text: 'Salad', correct: false },
      { text: 'Burger', correct: false },
    ],
    correctAnswer: 'Honey',
  },
  {
    question: 'What is the only fruit with seeds on the outside?',
    answers: [
      { text: 'Grape', correct: false },
      { text: 'Strawberry', correct: true },
      { text: 'Apple', correct: false },
      { text: 'None of them', correct: false },
    ],
    correctAnswer: 'Strawberry',
  },
  {
    question: 'What is the common name for the Chinese gooseberry fruit?',
    answers: [
      { text: 'Gooseberry', correct: false },
      { text: 'Chinese Berry', correct: false },
      { text: 'Kiwi', correct: true },
      { text: 'Blueberry', correct: false },
    ],
    correctAnswer: 'Kiwi',
  },

  {
    question: 'Which famous American beverage was first invented in 1886?',
    answers: [
      { text: 'Coca-Cola', correct: true },
      { text: 'Pepsi', correct: false },
      { text: '7up', correct: false },
      { text: 'Fanta', correct: false },
    ],
    correctAnswer: 'Coca-Cola',
  },
];

export default questions;
