import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Input from '../../components/Input';
import { updatePassword } from '../../stores/slices/authSlice';
import { setToastShow } from '../../stores/slices/toastSlice';
import usePasswordForm from './index.hooks';

function ChangePassword() {
  const {
    form, error, touched, handleOnBlur, handleFormChange, validateForm,
  } = usePasswordForm();
  const dispatch = useDispatch();
  const { userLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleFormSubmit = async () => {
    if (validateForm()) {
      dispatch(updatePassword(form))
        .unwrap()
        .then(() => {
          dispatch(
            setToastShow({
              toastShow: true,
              toastIsError: false,
              toastMessage: 'Password updated',
            }),
          );
          navigate('/profile');
        })
        .catch(async (err) => {
          dispatch(
            setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
          );
        });
    }
  };

  return (
    <Container>
      <div className="mt-24 w-full xl:flex-grow xl:px-56 flex flex-col items-center justify-center">
        <h1 className="text-3xl mb-3">Change Password</h1>
        <Input
          id="oldpassword"
          type="password"
          name="old_password"
          touched={touched.old_password}
          handleInputChange={handleFormChange}
          handleOnBlur={handleOnBlur}
          validation={error?.old_password}
          label="Old Password*"
          placeholder="old password"
          value={form?.old_password}
        />
        <Input
          id="newpassword"
          type="password"
          name="new_password"
          label="New Password*"
          touched={touched.new_password}
          handleInputChange={handleFormChange}
          handleOnBlur={handleOnBlur}
          validation={error?.new_password}
          placeholder="new password"
          value={form?.new_password}
        />
        <Input
          id="confirmpassword"
          type="password"
          name="confirm_password"
          label="Confirm Password*"
          touched={touched.confirm_password}
          handleInputChange={handleFormChange}
          handleOnBlur={handleOnBlur}
          validation={error?.confirm_password}
          placeholder="confirm password"
          value={form?.confirm_password}
        />
        <Button
          className="mt-2"
          name="update"
          handleClick={handleFormSubmit}
          isLoading={userLoading}
        >
          Change Password
        </Button>
      </div>
    </Container>
  );
}

export default ChangePassword;
