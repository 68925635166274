import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchCarts = createAsyncThunk('cart/fetch', () => client
  .get('/carts')
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const createCart = createAsyncThunk('cart/create', (data) => client
  .post(
    '/carts',
    JSON.stringify({
      menu_id: data.menu_id,
      quantity: data.quantity,
      options: JSON.stringify([{ value: data.size, additional_price: data.additionalPrice }]),
      note: data.note,
    }),
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const updateCartQuantity = createAsyncThunk('cart/update', (data) => client
  .patch(
    `/carts/${data.id}`,
    JSON.stringify({
      quantity: data.quantity,
    }),
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const deleteCart = createAsyncThunk('cart/delete', async ({ id }) => client
  .delete(`/carts/${id}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const deleteAllCart = createAsyncThunk('cart/deleteAll', async () => client
  .delete('/carts')
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));
const initialState = {
  carts: [],
  cartError: null,
  cartLoading: false,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCarts.fulfilled, (state, { payload }) => ({
      ...state,
      carts: payload.data,
      cartLoading: false,
    }));
    builder.addCase(fetchCarts.pending, (state) => ({
      ...state,
      cartError: null,
      cartLoading: true,
    }));
    builder.addCase(fetchCarts.rejected, (state, { error }) => ({
      ...state,
      cartError: error.message,
      cartLoading: false,
    }));
    builder.addCase(createCart.fulfilled, (state) => ({
      ...state,
      cartLoading: false,
    }));
    builder.addCase(createCart.pending, (state) => ({
      ...state,
      cartError: null,
      cartLoading: true,
    }));
    builder.addCase(createCart.rejected, (state, { error }) => ({
      ...state,
      cartError: error.message,
      cartLoading: false,
    }));
    builder.addCase(updateCartQuantity.fulfilled, (state) => ({
      ...state,
      cartLoading: false,
    }));
    builder.addCase(updateCartQuantity.pending, (state) => ({
      ...state,
      cartError: null,
      cartLoading: true,
    }));
    builder.addCase(updateCartQuantity.rejected, (state, { error }) => ({
      ...state,
      cartError: error.message,
      cartLoading: false,
    }));
    builder.addCase(deleteCart.fulfilled, (state) => ({
      ...state,
      cartLoading: false,
    }));
    builder.addCase(deleteCart.pending, (state) => ({
      ...state,
      cartError: null,
      cartLoading: true,
    }));
    builder.addCase(deleteCart.rejected, (state, { error }) => ({
      ...state,
      cartError: error.message,
      cartLoading: false,
    }));
    builder.addCase(deleteAllCart.fulfilled, (state) => ({
      ...state,
      cartLoading: false,
    }));
    builder.addCase(deleteAllCart.pending, (state) => ({
      ...state,
      cartError: null,
      cartLoading: true,
    }));
    builder.addCase(deleteAllCart.rejected, (state, { error }) => ({
      ...state,
      cartError: error.message,
      cartLoading: false,
    }));
  },
});

export default cartSlice.reducer;
