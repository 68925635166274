import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchPromotions = createAsyncThunk('promotion/fetch', () => client
  .get('/promotions')
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  promotions: [],
  promotionError: null,
  promotionLoading: false,
};

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromotions.fulfilled, (state, { payload }) => ({
      ...state,
      promotions: payload.data,
      promotionLoading: false,
    }));
    builder.addCase(fetchPromotions.pending, (state) => ({
      ...state,
      promotionError: null,
      promotionLoading: true,
    }));
    builder.addCase(fetchPromotions.rejected, (state, { error }) => ({
      ...state,
      promotionError: error.message,
      promotionLoading: false,
    }));
  },
});

export default promotionSlice.reducer;
