import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import loader from '../../assets/svgs/loader-black.svg';
import noData from '../../assets/svgs/no-data.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import {
  fetchGames, resetGameEnd, setGameStart, setGameStartFalse,
} from '../../stores/slices/gameSlice';
import { fetchLeaderboards } from '../../stores/slices/leaderboardSlice';

function Leaderboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leaderboards, leaderboardError, leaderboardLoading } = useSelector(
    (state) => state.leaderboard,
  );
  const { games, gameError, gameLoading } = useSelector((state) => state.game);

  useEffect(() => {
    dispatch(fetchLeaderboards());
    dispatch(fetchGames());
    dispatch(setGameStartFalse());
    dispatch(resetGameEnd());
  }, []);

  const handlePlayClick = () => {
    dispatch(setGameStart());
    navigate('/game');
  };

  return (
    <Container>
      {' '}
      {(leaderboardLoading || gameLoading) && (
        <div className="absolute h-screen w-[100%] left-0">
          <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
        </div>
      )}
      {(leaderboardError || gameError) && (
        <div className="h-screen w-[100%] py-20">
          <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
          <p className="text-center mt-4">Something went wrong</p>
        </div>
      )}
      {!leaderboardError && !leaderboardLoading && !gameError && !gameLoading && (
        <div className="mt-28">
          <h1 className="text-3xl text-center font-bold">Leaderboard</h1>
          <p className="text-base text-center mt-4">Play game to win some coupon!</p>
          <p className="text-base text-center">
            Today&apos;s chance:
            {' '}
            {games === null ? 3 : 3 - games.length}
          </p>
          <div className="flex justify-center mt-4">
            <Button
              className={`${games !== null && games.length === 3 ? 'bg-gray-400 hover:bg-gray-400 ' : ''}md:w-[50%]`}
              name="gameButton"
              handleClick={handlePlayClick}
              isDisabled={games === null ? false : games.length === 3}
            >
              Play Game
            </Button>
          </div>
          {leaderboards !== null && leaderboards.length > 0 && (
            <div className="flex justify-center mt-6">
              <ul className="divide-y w-full md:w-[50%] divide-gray-200">
                {leaderboards.map((x, index) => (
                  <li
                    className={`${
                      index === 0 ? 'bg-yellow-400 rounded-t-lg ' : ''
                    }py-3 px-4 sm:py-4 transition ease-in-out delay-150 hover:scale-110 hover:shadow-2xl`}
                    key={x.id}
                  >
                    <div className="flex justify-between space-x-4">
                      <div className="flex min-w-0 gap-4">
                        <p className="text-lg font-medium text-gray-900 truncate">{index + 1}</p>
                        <p className="text-lg text-gray-500 truncate dark:text-gray-400">
                          {x.user.fullname}
                        </p>
                      </div>
                      <div className="inline-flex items-end text-lg font-semibold text-gray-900 dark:text-white">
                        {x.score}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </Container>
  );
}

export default Leaderboard;
