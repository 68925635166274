import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import shopping from '../../assets/svgs/shopping-bag.svg';
import userIcon from '../../assets/svgs/user-circle.svg';
import { fetchProfile, logout } from '../../stores/slices/authSlice';
import { setCartModal } from '../../stores/slices/cartModalSlice';
import { fetchCarts } from '../../stores/slices/cartSlice';
import Button from '../Button';
import Container from '../Container';
import IconButton from '../IconButton';

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAvatarClicked, setisAvatarClicked] = useState(false);
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const { carts } = useSelector((state) => state.cart);

  const menus = [
    {
      link: '/',
      name: 'Menu',
    },
    {
      link: '/favorites',
      name: 'Favorite',
    },
    {
      link: '/histories',
      name: 'History',
    },
    {
      link: '/leaderboard',
      name: 'Game',
    },
  ];

  useEffect(() => {
    if (token) {
      dispatch(fetchProfile());
      dispatch(fetchCarts());
    }
  }, []);

  useEffect(() => {
    setOpen(false);
    setisAvatarClicked(false);
  }, [location]);

  const handleNavbarClick = () => {
    setisAvatarClicked(false);
    setOpen(!open);
  };

  const handleAvatarClick = () => {
    setOpen(false);
    setisAvatarClicked(!isAvatarClicked);
  };

  const navigateFromAvatar = (path) => {
    navigate(path);
    setisAvatarClicked(false);
  };

  const handleLogoutClick = () => {
    Cookies.remove('TOKEN');
    navigateFromAvatar('/');
    dispatch(logout());
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setOpen(false);
  };

  return (
    <nav className="bg-white py-2.5 fixed w-full z-20 top-0 left-0 border-b border-gray-200">
      <Container>
        <div className="flex flex-wrap justify-between items-center mx-auto">
          <img src={logo} alt="logo" className="mr-3 h-12 sm:h-16" />
          {!token ? (
            <div className="flex md:order-2 mt-2">
              <Button
                name="register"
                type="button"
                className="text-red-400 border border-red-400 bg-white hover:bg-white hover:text-red-700 hover:border-red-700"
                handleClick={() => {
                  navigate('/register');
                }}
              >
                Register
              </Button>
              <Button
                type="button"
                name="login"
                handleClick={() => {
                  navigate('/login');
                }}
              >
                Login
              </Button>
            </div>
          ) : (
            <>
              <div className="flex md:order-2">
                <IconButton className="inline-flex relative" icon={shopping} name="iconShopping" handleClick={() => { dispatch(setCartModal()); }}>
                  <div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white">{carts === null ? '0' : carts.length}</div>
                </IconButton>
                <IconButton icon={userIcon} name="iconUser" handleClick={handleAvatarClick} />
                <div
                  id="dropdownAvatar"
                  className={`${
                    isAvatarClicked ? 'block ' : 'hidden '
                  } absolute right-0 mt-12 mr-5 z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600`}
                >
                  <div className="py-3 px-4 text-sm text-gray-900">
                    <div>{user?.fullname}</div>
                    <div className="font-medium truncate">{user?.email}</div>
                  </div>
                  <ul
                    className="py-1 text-sm text-gray-700"
                    aria-labelledby="dropdownUserAvatarButton"
                  >
                    <li>
                      <button
                        type="button"
                        onClick={() => {
                          navigateFromAvatar('/profile');
                        }}
                        className="block w-full text-left py-2 px-4 hover:bg-gray-100"
                      >
                        Profile
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => {
                          navigateFromAvatar('/change-password');
                        }}
                        className="block w-full text-left  py-2 px-4 hover:bg-gray-100"
                      >
                        Change Password
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => {
                          navigateFromAvatar('/coupons');
                        }}
                        className="block w-full text-left  py-2 px-4 hover:bg-gray-100"
                      >
                        Owned Coupons
                      </button>
                    </li>
                  </ul>
                  <div className="py-1">
                    <button
                      type="button"
                      onClick={handleLogoutClick}
                      className="block w-full text-left  py-2 px-4 hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </div>
                </div>
                <button
                  data-collapse-toggle="navbar-sticky"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  aria-controls="navbar-sticky"
                  aria-expanded="false"
                  onClick={handleNavbarClick}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={`${
                  open ? 'block ' : 'hidden '
                } justify-between items-center w-full md:flex md:w-auto md:order-1`}
                id="navbar-sticky"
              >
                <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
                  {menus.map((menu) => (
                    <li key={menu.link}>
                      <Button
                        name="menuButton"
                        className={`${
                          location.pathname === menu.link
                            ? 'text-white bg-red-500 md:text-red-500 md:bg-transparent '
                            : 'text-gray-700 bg-transparent hover:bg-gray-100 md:hover:text-red-700 '
                        } block py-2 pr-4 pl-3 text-left rounded md:p-0 md:hover:bg-transparent focus:ring-0`}
                        aria-current="page"
                        handleClick={() => {
                          handleMenuItemClick(menu.link);
                        }}
                      >
                        {menu.name}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </Container>
    </nav>
  );
}

export default Navbar;
