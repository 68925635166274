import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchCategories = createAsyncThunk('category/fetch', ({ search, sort, sortBy }) => client
  .get(
    `public/categories?&sort=${sort}&sort_by=${sortBy}${search === '' ? '' : `&search=${search}`}`,
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  categories: [],
  categoryError: null,
  categoryLoading: false,
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => ({
      ...state,
      categories: payload.data,
      categoryLoading: false,
    }));
    builder.addCase(fetchCategories.pending, (state) => ({
      ...state,
      categoryError: null,
      categoryLoading: true,
    }));
    builder.addCase(fetchCategories.rejected, (state, { error }) => ({
      ...state,
      categoryError: error.message,
      categoryLoading: false,
    }));
  },
});

export default categorySlice.reducer;
