import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Input from '../../components/Input';
import { fetchProfile, updateProfile } from '../../stores/slices/authSlice';
import useProfileForm from './index.hooks';
import { setToastShow } from '../../stores/slices/toastSlice';
import loader from '../../assets/svgs/loader-black.svg';

function Profile() {
  const {
    form, error, touched, handleOnBlur, handleFormChange, validateForm, setForm,
  } = useProfileForm();
  const dispatch = useDispatch();
  const { user, authLoading, userLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    setForm(user);
  }, [user]);

  const handleFormSubmit = async () => {
    if (validateForm()) {
      dispatch(updateProfile(form))
        .unwrap()
        .then(() => {
          dispatch(
            setToastShow({ toastShow: true, toastIsError: false, toastMessage: 'Profile updated' }),
          );
        })
        .catch(async (err) => {
          dispatch(
            setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
          );
        });
    }
  };

  return (
    <Container>
      <div className="mt-24 w-full xl:flex-grow xl:px-56 flex flex-col items-center justify-center">
        <h1 className="text-3xl mb-3">Profile</h1>
        {authLoading && (
          <div className="h-screen w-[100%]">
            <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
          </div>
        )}
        {!authLoading && (
          <>
            <img
              className="w-48 h-48 mb-2 rounded-full"
              src={user?.profile_picture}
              alt="description"
            />
            <Input
              id="username"
              type="text"
              name="username"
              touched={touched.username}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.username}
              label="Username*"
              placeholder="username"
              isDisabled
              value={form?.username}
            />
            <Input
              id="email"
              type="email"
              name="email"
              label="Email*"
              touched={touched.email}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.email}
              placeholder="email@email.com"
              isDisabled
              value={form?.email}
            />
            <Input
              id="fullname"
              type="text"
              name="fullname"
              label="Fullname*"
              touched={touched.fullname}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.fullname}
              placeholder="fullname"
              value={form?.fullname}
            />
            <Input
              id="phone"
              type="text"
              name="phone"
              label="Phone Number*"
              touched={touched.phone}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.phone}
              placeholder="08xxxxxxxxx"
              value={form?.phone}
            />
            <TextArea
              id="profilePic"
              name="profile_picture"
              label="Profile Picture URL"
              placeholder="http://www.photo.com"
              touched={touched.profile_picture}
              handleInputChange={handleFormChange}
              handleOnBlur={handleOnBlur}
              validation={error?.profile_picture}
              value={form?.profile_picture}
            />
            <Button name="update" handleClick={handleFormSubmit} isLoading={userLoading}>
              Update Data
            </Button>
          </>
        )}
      </div>
    </Container>
  );
}

export default Profile;
