import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import client from '../config/client';

export const register = createAsyncThunk('auth/register', (data) => {
  const {
    fullname, username, email, password, phone,
  } = data;

  return client
    .post(
      '/register',
      JSON.stringify({
        fullname,
        username,
        email,
        password,
        phone,
      }),
    )
    .then((response) => {
      Cookies.set('TOKEN', response.data.data.token);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
});

export const login = createAsyncThunk('auth/login', (data) => {
  const { username, password } = data;

  return client
    .post(
      '/login',
      JSON.stringify({
        username,
        password,
      }),
    )
    .then((response) => {
      Cookies.set('TOKEN', response.data.data.token);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
});

export const fetchProfile = createAsyncThunk('auth/fetchProfile', () => client
  .get('/profiles')
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const updateProfile = createAsyncThunk('auth/updateProfile', (data) => {
  const {
    fullname, phone, profile_picture: profilePicture,
  } = data;
  return client
    .put(
      '/profiles',
      JSON.stringify({
        fullname,
        phone,
        profile_picture: profilePicture,
      }),
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
});

export const updatePassword = createAsyncThunk('auth/updatePassword', (data) => {
  const {
    old_password: oldPassword, new_password: newPassword, confirm_password: confirmPassword,
  } = data;
  return client
    .put(
      '/change-password',
      JSON.stringify({
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }),
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
});

const initialState = {
  token: Cookies.get('TOKEN'),
  user: {},
  authError: null,
  authLoading: false,
  userError: null,
  userLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => ({ ...state, token: '', user: {} }),
  },
  extraReducers: (builder) => {
    builder.addCase(register.fulfilled, (state, { payload }) => ({
      ...state,
      token: payload.data.token,
      authLoading: false,
    }));
    builder.addCase(register.pending, (state) => ({
      ...state,
      authError: null,
      authLoading: true,
    }));
    builder.addCase(register.rejected, (state, { error }) => ({
      ...state,
      authError: error.message,
      authLoading: false,
    }));
    builder.addCase(login.fulfilled, (state, { payload }) => ({
      ...state,
      token: payload.data.token,
      authLoading: false,
    }));
    builder.addCase(login.pending, (state) => ({
      ...state,
      authError: null,
      authLoading: true,
    }));
    builder.addCase(login.rejected, (state, { error }) => ({
      ...state,
      authError: error.message,
      authLoading: false,
    }));
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => ({
      ...state,
      user: payload.data,
      authLoading: false,
    }));
    builder.addCase(fetchProfile.pending, (state) => ({
      ...state,
      authError: null,
      authLoading: true,
    }));
    builder.addCase(fetchProfile.rejected, (state, { error }) => ({
      ...state,
      authError: error.message,
      authLoading: false,
    }));
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => ({
      ...state,
      user: payload.data,
      userLoading: false,
    }));
    builder.addCase(updateProfile.pending, (state) => ({
      ...state,
      userError: null,
      userLoading: true,
    }));
    builder.addCase(updateProfile.rejected, (state, { error }) => ({
      ...state,
      userError: error.message,
      userLoading: false,
    }));
    builder.addCase(updatePassword.fulfilled, (state) => ({
      ...state,
      userLoading: false,
    }));
    builder.addCase(updatePassword.pending, (state) => ({
      ...state,
      userError: null,
      userLoading: true,
    }));
    builder.addCase(updatePassword.rejected, (state, { error }) => ({
      ...state,
      userError: error.message,
      userLoading: false,
    }));
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
