import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../assets/svgs/loader-black.svg';
import noData from '../../assets/svgs/no-data.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Select from '../../components/Select';
import convertToRupiah from '../../helpers/convertToRupiah';
import { fetchCategories } from '../../stores/slices/menuCategorySlice';
import { fetchOrders } from '../../stores/slices/orderSlice';
import ActionModal from './Components/ActionModal';
import useReviewForm from './index.hooks';

function History() {
  const dispatch = useDispatch();
  const { orders, orderError, orderLoading } = useSelector((state) => state.order);
  const { categories, categoryError, categoryLoading } = useSelector((state) => state.menuCategory);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [mappedOrders, setMappedOrders] = useState([]);
  const [item, setItem] = useState('');
  const [modal, setModal] = useState(false);
  const { form, setForm, setTouched } = useReviewForm();
  const [sort, setSort] = useState('');
  const sortOptions = [
    {
      value: '',
      label: 'None',
    },
    {
      value: 'asc',
      label: 'Ascending',
    },
    {
      value: 'desc',
      label: 'Descending',
    },
  ];

  const initTouched = {
    rating: false,
    description: false,
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };

  useEffect(() => {
    dispatch(fetchCategories({ sort: 'asc', sortBy: '', search: '' }));
    dispatch(fetchOrders());
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const mapOrders = () => {
    let tempData = [];
    if (orders !== null && orders.length > 0) {
      tempData = orders;
      // filter by category
      if (selectedCategory !== 'all') {
        tempData = tempData.filter(
          (x) => x.order_details.find(
            (detail) => detail.menu.menu_category.id === selectedCategory,
          ),
        );
      }

      // sort by price
      const arrayForSort = [...tempData];
      if (sort === 'desc') {
        arrayForSort.sort(
          (a, b) => parseInt(b.total_price - b.discount_price, 10)
            - parseInt(a.total_price - a.discount_price, 10),
        );
      } else if (sort === 'asc') {
        arrayForSort.sort(
          (a, b) => parseInt(a.total_price - a.discount_price, 10)
            - parseInt(b.total_price - b.discount_price, 10),
        );
      }
      tempData = arrayForSort;

      // search by menu name
      tempData = tempData.filter(
        (x) => x.order_details.find(
          (detail) => detail.menu.name.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    }
    setMappedOrders(tempData);
  };

  useEffect(() => {
    mapOrders();
  }, [orders, search, selectedCategory, sort]);

  const handleSelectCategory = (id) => {
    setSelectedCategory(id);
  };

  const renderCategories = () => {
    const initCategories = [
      {
        id: 'all',
        name: 'All',
      },
    ];

    initCategories.push(...categories);

    return initCategories.map((category) => (
      <Button
        key={category.id}
        type="button"
        name="categoryButton"
        className={`${
          category.id === selectedCategory
            ? 'bg-red-400 text-white '
            : 'text-red-400 border border-red-400 bg-white hover:bg-white hover:text-red-700 hover:border-red-700 '
        } inline-flex w-fit items-center px-5 mt-2 py-2.5 text-sm font-medium `}
        handleClick={() => handleSelectCategory(category.id)}
      >
        {category.name}
      </Button>
    ));
  };

  const handleReviewClick = (data) => {
    setItem(data.id);
    setForm({
      rating: 1,
      description: '',
    });
    setTouched({
      rating: false,
      descripton: false,
    });
    setModal(true);
  };

  const onSave = (status) => {
    if (status) {
      dispatch(
        fetchOrders(),
      );
    }
    setModal(false);
  };

  const onCloseModal = () => {
    setModal(false);
  };

  return (
    <Container>
      {(orderLoading || categoryLoading) && (
        <div className="absolute h-screen w-[100%] left-0">
          <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
        </div>
      )}
      {(orderError || categoryError) && (
        <div className="h-screen w-[100%] py-20">
          <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
          <p className="text-center mt-4">Something went wrong</p>
        </div>
      )}
      {!orderError && !categoryError && (
        <div className="w-full flex flex-col gap-y-2 md:flex-row md:gap-y-0 justify-between mt-28">
          <div className="flex relative w-[100%] md:w-[30%]">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              className="block p-2 pl-10 max-w-7xl w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search"
              onChange={handleSearch}
              value={search}
            />
          </div>
          <div className="flex">
            <p className="mt-2 mr-2">Sort By Price</p>
            <Select
              id="sort"
              name="sort"
              value={sort}
              handleChange={handleSortChange}
              lists={sortOptions}
            />
          </div>
        </div>
      )}
      {!orderError && !orderLoading && (
        <>
          <ActionModal
            modal={modal}
            onCloseModal={onCloseModal}
            onSave={onSave}
            initForm={form}
            item={item}
            initTouched={initTouched}
          />
          <div className="w-full mt-2 flex flex-row gap-2 overflow-scroll no-scrollbar sm:justify-start">
            {renderCategories()}
          </div>
          <div className="mb-4">
            {mappedOrders.length > 0 ? (
              <div className="grid grid-cols-1 gap-2">
                {mappedOrders.map((x) => {
                  const total = x.total_price - x.discount_price;
                  return (
                    <div key={x.id} className="p-4 w-full bg-white rounded-lg border shadow-md sm:p-8">
                      <div className="flex justify-between items-center mb-4">
                        <h5 className="text-xl font-bold leading-none text-gray-900">
                          {moment(x.order_date).format('DD MMMM YYYY - HH:mm')}
                        </h5>
                        {x.status === 'In Progress' && (
                          <p className="text-sm font-medium text-gray-600">{x.status}</p>
                        )}
                        {x.status === 'In Transit' && (
                          <p className="text-sm font-medium text-blue-600">{x.status}</p>
                        )}
                        {x.status === 'Received' && (
                          <p className="text-sm font-medium text-green-600">{x.status}</p>
                        )}
                      </div>
                      <div className="flow-root">
                        <ul className="divide-y divide-gray-200">
                          {x.order_details.map((detail) => {
                            const option = JSON.parse(detail.options);
                            return (
                              <li key={detail.id} className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                  <div className="flex-shrink-0">
                                    <img
                                      className="w-20 h-20 max-w-[80px] rounded-lg object-contain"
                                      src={detail.menu.photo_url}
                                      alt="food"
                                    />
                                  </div>
                                  <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 text-clip">
                                      {detail.menu.name}
                                      {' '}
                                      x
                                      {detail.quantity}
                                    </p>
                                    {detail.menu.menu_category.name !== '' && (
                                      <span className="bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded">
                                        {detail.menu.menu_category.name}
                                      </span>
                                    )}
                                    <p className="text-xs text-gray-500 text-clip">
                                      {option[0].value}
                                      {' '}
                                      +Rp.
                                      {' '}
                                      {convertToRupiah(option[0].additional_price)}
                                    </p>
                                    <p className="text-sm text-gray-500 text-clip">
                                      Note:
                                      {' '}
                                      {detail.note}
                                    </p>
                                  </div>
                                  <div className="inline-flex items-center text-base font-semibold text-gray-900">
                                    Rp.
                                    {' '}
                                    {convertToRupiah(detail.price)}
                                  </div>
                                </div>
                                {!detail.is_reviewed && x.status === 'Received' && (
                                  <Button
                                    className={`w-fit px-3 py-1 mt-2 ${
                                      detail.is_reviewed ? 'bg-gray-200 hover:bg-gray-200 ' : ''
                                    }`}
                                    name="reviewButton"
                                    handleClick={() => {
                                      handleReviewClick(detail);
                                    }}
                                    isDisabled={detail.is_reviewed}
                                  >
                                    Give Review
                                  </Button>
                                )}
                              </li>
                            );
                          })}
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between mt-2">
                              <div className="flex">
                                <p>Subtotal:</p>
                              </div>
                              <div className="flex">
                                <p>
                                  {' '}
                                  Rp.
                                  {' '}
                                  {convertToRupiah(x.total_price)}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between">
                              <div className="flex">
                                <p>Discount:</p>
                              </div>
                              <div className="flex text-red-400">
                                <p>
                                  {' '}
                                  -Rp.
                                  {' '}
                                  {convertToRupiah(x.discount_price)}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between text-lg">
                              <div className="flex font-bold">
                                <p>Total:</p>
                              </div>
                              <div className="flex font-bold">
                                <p>
                                  Rp.
                                  {' '}
                                  {total < 0 ? 0 : convertToRupiah(total)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="py-20">
                <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
                <p className="text-center mt-4">No Orders</p>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  );
}

export default History;
