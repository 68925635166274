import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchLeaderboards = createAsyncThunk('leaderboard/fetch', () => client
  .get(
    'leaderboards',
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  leaderboards: [],
  leaderboardError: null,
  leaderboardLoading: false,
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLeaderboards.fulfilled, (state, { payload }) => ({
      ...state,
      leaderboards: payload.data,
      leaderboardLoading: false,
    }));
    builder.addCase(fetchLeaderboards.pending, (state) => ({
      ...state,
      leaderboardError: null,
      leaderboardLoading: true,
    }));
    builder.addCase(fetchLeaderboards.rejected, (state, { error }) => ({
      ...state,
      leaderboardError: error.message,
      leaderboardLoading: false,
    }));
  },
});

export default leaderboardSlice.reducer;
