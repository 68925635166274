import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../../components/Container';
import food from '../../assets/svgs/food.svg';
import { fetchCoupons } from '../../stores/slices/couponSlice';
import loader from '../../assets/svgs/loader-black.svg';
import noData from '../../assets/svgs/no-data.svg';
import convertToRupiah from '../../helpers/convertToRupiah';

function OwnedCoupon() {
  const { coupons, couponLoading } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCoupons());
  }, []);

  return (
    <Container>
      <div className="mt-24 w-full xl:flex-grow xl:px-56 flex flex-col items-center justify-center">
        <h1 className="text-3xl mb-8">Owned Coupons</h1>
        {couponLoading && (
          <div className="h-screen w-[100%]">
            <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
          </div>
        )}
        {!couponLoading && coupons !== null && coupons.length > 0 ? (
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-4">
            {coupons.map((coupon) => (
              <div className="flex flex-col gap-2 items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl transition ease-in-out delay-150 hover:scale-110 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-20 rounded-t-lg md:rounded-none md:rounded-l-lg"
                  src={food}
                  alt=""
                />
                <div className="flex flex-col justify-between p-4 leading-normal text-center">
                  <h5 className="mb-2 text-2xl font-bold text-red-500 tracking-wider">
                    {coupon.name}
                  </h5>
                  <p className="mb-3 font-normal text-gray-700">
                    Rp.
                    {' '}
                    {convertToRupiah(coupon.amount)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="py-20">
            <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
            <p className="text-center mt-4">No Coupons</p>
          </div>
        )}
      </div>
    </Container>
  );
}

export default OwnedCoupon;
