import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import cartReducer from './slices/cartSlice';
import couponReducer from './slices/couponSlice';
import menuCategoryReducer from './slices/menuCategorySlice';
import menuReducer from './slices/menuSlice';
import reviewReducer from './slices/reviewSlice';
import toastReducer from './slices/toastSlice';
import cartModalReducer from './slices/cartModalSlice';
import paymentReducer from './slices/paymentSlice';
import promotionReducer from './slices/promotionSlice';
import orderReducer from './slices/orderSlice';
import leaderboardReducer from './slices/leaderboardSlice';
import gameReducer from './slices/gameSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    coupon: couponReducer,
    menu: menuReducer,
    menuCategory: menuCategoryReducer,
    review: reviewReducer,
    cart: cartReducer,
    cartModal: cartModalReducer,
    payment: paymentReducer,
    promotion: promotionReducer,
    order: orderReducer,
    leaderboard: leaderboardReducer,
    game: gameReducer,
  },
});

export default store;
