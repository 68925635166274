import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../../../assets/svgs/loader-black.svg';
import noData from '../../../../assets/svgs/no-data.svg';
import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import heartRed from '../../../../assets/svgs/heart-red.svg';
import heartWhite from '../../../../assets/svgs/heart-white.svg';
import Modal from '../../../../components/Modal';
import RadioButton from '../../../../components/RadioButton';
import ReviewCard from '../../../../components/ReviewCard';
import TextArea from '../../../../components/TextArea';
import convertToRupiah from '../../../../helpers/convertToRupiah';
import { createCart, fetchCarts } from '../../../../stores/slices/cartSlice';
import { fetchMenu, updateFavorite } from '../../../../stores/slices/menuSlice';
import { fetchReviews } from '../../../../stores/slices/reviewSlice';
import { setToastShow } from '../../../../stores/slices/toastSlice';

function DetailModal(props) {
  const { modal, onCloseModal, item } = props;
  const dispatch = useDispatch();
  const { menu, menuLoading, updateMenuLoading } = useSelector((state) => state.menu);
  const { reviews, reviewLoading } = useSelector((state) => state.review);
  const { cartLoading } = useSelector((state) => state.cart);
  const [options, setOptions] = useState([]);
  const [size, setSize] = useState('');
  const [note, setNote] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [additionalPrice, setAdditionalPrice] = useState(0);

  useEffect(() => {
    if (modal) {
      dispatch(fetchMenu({ id: item }))
        .unwrap()
        .then(() => {})
        .catch((err) => {
          setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message });
          onCloseModal();
        });
      dispatch(fetchReviews({ id: item }))
        .unwrap()
        .then(() => {})
        .catch((err) => {
          setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message });
          onCloseModal();
        });
    }
    setSize('');
    setQuantity(1);
    setAdditionalPrice(0);
    setNote('');
  }, [modal]);

  useEffect(() => {
    if (modal) {
      if (menu.options !== '') {
        setOptions(JSON.parse(menu.options));
      }
    }
  }, [menu]);

  const radioChangeHandler = (e, index) => {
    const tempOptions = [...options];
    let tempPrice = additionalPrice;
    tempOptions[0].options.forEach((x, i) => {
      if (i === index) {
        tempPrice = x.additional_price;
      }
      return x;
    });
    setAdditionalPrice(tempPrice);
    setSize(e.target.value);
  };

  const handleQuantityChange = (operands) => {
    if (operands === '-') {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    } else {
      setQuantity(quantity + 1);
    }
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleAddToCart = () => {
    dispatch(
      createCart({
        menu_id: item,
        note,
        size,
        additionalPrice,
        quantity,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(fetchCarts());
        dispatch(
          setToastShow({
            toastShow: true,
            toastIsError: false,
            toastMessage: 'successfully added to cart',
          }),
        );
        onCloseModal();
      })
      .catch((err) => {
        dispatch(setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }));
        onCloseModal();
      });
  };

  const handleFavoriteClick = () => {
    dispatch(updateFavorite({ id: item }));
  };

  return (
    <Modal show={modal} className="max-h-screen">
      <div className="relative p-4 w-full max-w-md h-full mx-auto my-auto md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 z-30 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
            onClick={onCloseModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6">
            {(menuLoading || reviewLoading) && (
              <div className="w-[100%] left-0">
                <img className="h-[50%] mx-auto" src={loader} alt="loading" />
              </div>
            )}
            {!menuLoading && menu !== null && (
              <>
                <div className="relative">
                  <img src={menu.photo_url} alt="menu" className="w-full object-cover" />
                  <IconButton
                    className="absolute right-5 -bottom-3 bg-white hover:bg-gray-200 shadow-lg"
                    icon={menu.is_favorite ? heartRed : heartWhite}
                    name="iconUser"
                    handleClick={handleFavoriteClick}
                    isLoading={updateMenuLoading}
                    isDisabled={updateMenuLoading}
                  />
                </div>
                <div className="overflow-y-scroll max-h-80">
                  <h5 className="text-2xl font-bold tracking-tight text-gray-900">{menu.name}</h5>
                  {menu.menu_category.name !== '' && (
                    <span className="bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded">
                      {menu.menu_category.name}
                    </span>
                  )}
                  <p className="mb-3 mt-3 ont-normal text-gray-700">
                    Rp.
                    {' '}
                    {convertToRupiah(menu.price)}
                  </p>
                  <Button
                    className={`w-fit px-3 py-1 ${
                      quantity === 1 ? 'bg-gray-200 hover:bg-gray-200 ' : ''
                    }`}
                    name="decrementButton"
                    handleClick={() => {
                      handleQuantityChange('-');
                    }}
                    isDisabled={quantity === 1}
                  >
                    -
                  </Button>
                  {quantity}
                  <Button
                    className="w-fit px-2.5 py-[0.22rem] ml-2"
                    name="incrementButton"
                    handleClick={() => {
                      handleQuantityChange('+');
                    }}
                  >
                    +
                  </Button>
                  {options.map((x) => (
                    <div key={x.id}>
                      <p>{x?.title}</p>
                      {x?.options.map((option, index) => (
                        <RadioButton
                          key={`${option.id}-${option.value}`}
                          changed={(e) => {
                            radioChangeHandler(e, index);
                          }}
                          id={option.id}
                          isSelected={size === option.value}
                          label={`${option.value} (+Rp. ${convertToRupiah(
                            option.additional_price,
                          )})`}
                          value={option.value}
                        />
                      ))}
                    </div>
                  ))}
                  <TextArea
                    id="notes"
                    name="note"
                    value={note}
                    handleInputChange={handleNoteChange}
                    label="Note"
                    placeholder="note"
                  />
                  {!reviewLoading && (
                    <>
                      <p>Reviews</p>
                      {reviews !== null && reviews.length > 0 ? (
                        <div className="mt-5 grid grid-cols-1 gap-4">
                          {reviews.map((x) => (
                            <ReviewCard key={x.id} item={x} />
                          ))}
                        </div>
                      ) : (
                        <div className="py-10">
                          <img className="h-[10vh] mx-auto" src={noData} alt="no data" />
                          <p className="text-center mt-4">No Reviews</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <p className="text-2xl font-bold">Subtotal :</p>
                <p className="text-lg font-bold">
                  Rp.
                  {' '}
                  {convertToRupiah(
                    (parseInt(menu.price, 10) + parseInt(additionalPrice, 10)) * quantity,
                  )}
                </p>
              </>
            )}
            <div className="flex justify-center mt-4">
              {!menuLoading && (
                <Button
                  name="saveModal"
                  className={`${
                    size === '' ? 'bg-gray-400 hover:bg-gray-400 ' : 'bg-red-600 hover:bg-red-800 '
                  }text-white`}
                  isDisabled={size === ''}
                  handleClick={handleAddToCart}
                  isLoading={cartLoading}
                >
                  Add to cart
                </Button>
              )}

              <Button
                name="closeModal"
                className="text-gray-500 bg-white hover:bg-gray-100"
                handleClick={onCloseModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

DetailModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default DetailModal;
