import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import registerImg from '../../assets/svgs/register.svg';
import x from '../../assets/svgs/x.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import IconButton from '../../components/IconButton';
import Input from '../../components/Input';
import { register } from '../../stores/slices/authSlice';
import { setToastShow } from '../../stores/slices/toastSlice';
import useRegisterForm from './index.hooks';

function Register() {
  const {
    form, error, touched, handleOnBlur, handleFormChange, validateForm,
  } = useRegisterForm();
  const { authLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = async () => {
    if (validateForm()) {
      dispatch(register(form))
        .unwrap()
        .then(() => {
          navigate('/');
        })
        .catch(async (err) => {
          dispatch(
            setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
          );
        });
    }
  };
  return (
    <Container>
      <IconButton
        className="mt-6 bg-gray-300 border-0 hover:bg-gray-400 focus:ring-gray-400"
        name="closeRegister"
        handleClick={() => {
          navigate('/');
        }}
        icon={x}
      />
      <div className="mt-3 min-h-[90vh] flex flex-col justify-center xl:flex-row-reverse xl:items-center">
        <img
          className="h-40 transition-all ease-in-out duration-500 xl:flex-grow xl:h-96 xl:rotate-6 xl:-translate-x-32"
          src={registerImg}
          alt="register-img"
        />
        <div className="mt-8 xl:flex-grow xl:px-56">
          <h1 className="text-3xl mb-3">Register</h1>
          <Input
            id="fullname"
            type="text"
            name="fullname"
            touched={touched.fullname}
            handleInputChange={handleFormChange}
            handleOnBlur={handleOnBlur}
            validation={error?.fullname}
            label="Fullname*"
            placeholder="fullname"
            value={form.fullname}
          />
          <Input
            id="username"
            type="text"
            name="username"
            touched={touched.username}
            handleInputChange={handleFormChange}
            handleOnBlur={handleOnBlur}
            validation={error?.username}
            label="Username*"
            placeholder="username"
            value={form.username}
          />
          <Input
            id="phone"
            type="text"
            name="phone"
            touched={touched.phone}
            handleInputChange={handleFormChange}
            handleOnBlur={handleOnBlur}
            validation={error?.phone}
            label="Phone Number*"
            placeholder="08xxxxxxxxx"
            value={form.phone}
          />
          <Input
            id="email"
            type="email"
            name="email"
            label="Email*"
            touched={touched.email}
            handleInputChange={handleFormChange}
            handleOnBlur={handleOnBlur}
            validation={error?.email}
            placeholder="email@email.com"
            value={form.email}
          />
          <Input
            id="password"
            type="password"
            name="password"
            touched={touched.password}
            handleInputChange={handleFormChange}
            handleOnBlur={handleOnBlur}
            validation={error?.password}
            label="Password*"
            placeholder="password"
            value={form.password}
          />
          <p className="mt-4 mb-2">
            Already have an account?
            {' '}
            <Link to="/login" className="text-red-500 cursor-pointer hover:text-red-800">
              Login
            </Link>
          </p>
          <Button name="register" handleClick={handleFormSubmit} isLoading={authLoading}>
            Register
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default Register;
