import { useEffect, useState } from 'react';

const useProfileForm = () => {
  const emailTest = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneTest = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;
  const [form, setForm] = useState({
    fullname: '',
    username: '',
    email: '',
    phone: '',
    profile_picture: '',
  });
  const [touched, setTouched] = useState({
    fullname: false,
    username: false,
    email: false,
    phone: false,
    profile_picture: false,
  });
  const [error, setError] = useState({
  });

  const validate = () => {
    const errors = {};
    if (!form.fullname) {
      errors.fullname = 'Please input your fullname';
    }

    if (!form.username) {
      errors.username = 'Please input your username';
    }

    if (!form.phone) {
      errors.phone = 'Please input your phone number';
    } else if (!phoneTest.test(form.phone)) {
      errors.phone = 'Please input valid phone number';
    }

    if (!form.email) {
      errors.email = 'Please input your email';
    } else if (!emailTest.test(form.email)) {
      errors.email = 'Please input a valid email';
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [form]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnBlur = (e) => {
    const { name } = e.target;
    setTouched((prevState) => ({ ...prevState, [name]: true }));
  };

  const validateForm = () => {
    setTouched({
      fullname: true,
      username: true,
      email: true,
      phone: true,
      profile_picture: true,
    });
    return validate();
  };

  return {
    form,
    error,
    touched,
    handleOnBlur,
    handleFormChange,
    validateForm,
    setForm,
  };
};

export default useProfileForm;
