import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchGames = createAsyncThunk('game/fetch', () => client
  .get('games')
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const createGame = createAsyncThunk('game/create', (data) => client
  .post(
    '/games',
    JSON.stringify({
      is_won: data.is_won,
    }),
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  games: [],
  gameError: null,
  gameLoading: false,
  gameStart: false,
  gameEnd: false,
  gameReward: null,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameStart: (state) => ({ ...state, gameStart: true }),
    setGameStartFalse: (state) => ({ ...state, gameStart: false }),
    setGameEnd: (state) => ({ ...state, gameEnd: true }),
    resetGameEnd: (state) => ({ ...state, gameEnd: false }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGames.fulfilled, (state, { payload }) => ({
      ...state,
      games: payload.data,
      gameLoading: false,
    }));
    builder.addCase(fetchGames.pending, (state) => ({
      ...state,
      gameError: null,
      gameLoading: true,
    }));
    builder.addCase(fetchGames.rejected, (state, { error }) => ({
      ...state,
      gameError: error.message,
      gameLoading: false,
    }));
    builder.addCase(createGame.fulfilled, (state, { payload }) => ({
      ...state,
      gameReward: payload.data,
      gameLoading: false,
    }));
    builder.addCase(createGame.pending, (state) => ({
      ...state,
      gameError: null,
      gameLoading: true,
    }));
    builder.addCase(createGame.rejected, (state, { error }) => ({
      ...state,
      gameError: error.message,
      gameLoading: false,
    }));
  },
});

export const {
  setGameStart, setGameEnd, setGameStartFalse, resetGameEnd,
} = gameSlice.actions;

export default gameSlice.reducer;
