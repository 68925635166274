import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import register from '../../assets/svgs/register.svg';
import x from '../../assets/svgs/x.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import IconButton from '../../components/IconButton';
import Input from '../../components/Input';
import { login } from '../../stores/slices/authSlice';
import { setToastShow } from '../../stores/slices/toastSlice';
import useLoginForm from './index.hooks';

function Login() {
  const {
    form, error, touched, handleOnBlur, handleFormChange, validateForm,
  } = useLoginForm();
  const { authLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = () => {
    if (validateForm()) {
      dispatch(login(form))
        .unwrap()
        .then(() => {
          navigate('/');
        })
        .catch(async (err) => {
          dispatch(
            setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
          );
        });
    }
  };
  return (
    <Container>
      <IconButton
        className="mt-6 bg-gray-300 border-0 hover:bg-gray-400 focus:ring-gray-400"
        name="closeLogin"
        handleClick={() => {
          navigate('/');
        }}
        icon={x}
      />
      <div className="mt-3 min-h-[90vh] flex flex-col justify-center xl:flex-row xl:items-center">
        <img
          className="h-40 transition-all ease-in-out duration-500 xl:flex-grow xl:h-80 xl:rotate-6 xl:translate-x-32"
          src={register}
          alt="register-img"
        />
        <div className="mt-8 xl:flex-grow xl:px-56">
          <h1 className="text-3xl mb-3">Login</h1>
          <Input
            id="username"
            type="text"
            name="username"
            touched={touched.username}
            handleInputChange={handleFormChange}
            handleOnBlur={handleOnBlur}
            validation={error?.username}
            label="Username*"
            placeholder="username"
            value={form.username}
          />
          <Input
            id="password"
            type="password"
            name="password"
            touched={touched.password}
            handleInputChange={handleFormChange}
            handleOnBlur={handleOnBlur}
            validation={error?.password}
            value={form.password}
            label="Password*"
            placeholder="password"
          />
          <p className="mt-4 mb-2">
            Don&apos;t have an account?
            {' '}
            <Link to="/register" className="text-red-500 cursor-pointer hover:text-red-800">
              Register here
            </Link>
          </p>
          <Button name="login" handleClick={handleFormSubmit} isLoading={authLoading}>
            Login
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default Login;
