import React from 'react';
import PropTypes from 'prop-types';

function RadioButton(props) {
  const {
    changed, id, isSelected, label, value,
  } = props;
  return (
    <div className="flex items-center mb-4">
      <input className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " id={id} onChange={changed} value={value} type="radio" checked={isSelected} />
      <label className="ml-2 text-sm font-medium text-gray-900" htmlFor={id}>{label}</label>
    </div>
  );
}

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  changed: PropTypes.func,
};

RadioButton.defaultProps = {
  changed: () => {},
};

export default RadioButton;
