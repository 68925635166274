import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchMenus = createAsyncThunk('menus/fetch', ({
  search, sort, sortBy, filter,
}) => client
  .get(
    `public/menus?&sort=${sort}&sort_by=${sortBy}${search === '' ? '' : `&search=${search}`}${
      filter === '' || filter === 'all' ? '' : `&filter=${filter}`
    }`,
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const fetchMenu = createAsyncThunk('menu/fetch', ({ id }) => client
  .get(`menus/${id}`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const updateFavorite = createAsyncThunk('menu/update-favorite', ({ id }) => client
  .put(`menus/${id}/favorites`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const fetchFavorites = createAsyncThunk(
  'favorites/fetch',
  ({
    search, sort, sortBy, filter,
  }) => client
    .get(
      `favorites?&sort=${sort}&sort_by=${sortBy}${search === '' ? '' : `&search=${search}`}${
        filter === '' || filter === 'all' ? '' : `&filter=${filter}`
      }`,
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    }),
);

const initialState = {
  menus: [],
  favorites: [],
  menu: null,
  menuError: null,
  menuLoading: false,
  menusError: null,
  menusLoading: false,
  updateMenuLoading: false,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.fulfilled, (state, { payload }) => ({
      ...state,
      menus: payload.data,
      menusLoading: false,
    }));
    builder.addCase(fetchMenus.pending, (state) => ({
      ...state,
      menusError: null,
      menusLoading: true,
    }));
    builder.addCase(fetchMenus.rejected, (state, { error }) => ({
      ...state,
      menusError: error.message,
      menusLoading: false,
    }));
    builder.addCase(fetchFavorites.fulfilled, (state, { payload }) => ({
      ...state,
      favorites: payload.data,
      menusLoading: false,
    }));
    builder.addCase(fetchFavorites.pending, (state) => ({
      ...state,
      menusError: null,
      menusLoading: true,
    }));
    builder.addCase(fetchFavorites.rejected, (state, { error }) => ({
      ...state,
      menusError: error.message,
      menusLoading: false,
    }));
    builder.addCase(fetchMenu.fulfilled, (state, { payload }) => ({
      ...state,
      menu: payload.data,
      menuLoading: false,
    }));
    builder.addCase(fetchMenu.pending, (state) => ({
      ...state,
      menuError: null,
      menuLoading: true,
    }));
    builder.addCase(fetchMenu.rejected, (state, { error }) => ({
      ...state,
      menuError: error.message,
      menuLoading: false,
    }));
    builder.addCase(updateFavorite.fulfilled, (state, { payload }) => ({
      ...state,
      menu: payload.data,
      updateMenuLoading: false,
    }));
    builder.addCase(updateFavorite.pending, (state) => ({
      ...state,
      menuError: null,
      updateMenuLoading: true,
    }));
    builder.addCase(updateFavorite.rejected, (state, { error }) => ({
      ...state,
      menuError: error.message,
      updateMenuLoading: false,
    }));
  },
});

export default menuSlice.reducer;
