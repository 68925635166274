import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import loader from '../../assets/svgs/loader-black.svg';
import noData from '../../assets/svgs/no-data.svg';
import trash from '../../assets/svgs/trash.svg';
import convertToRupiah from '../../helpers/convertToRupiah';
import { setCartModal } from '../../stores/slices/cartModalSlice';
import {
  deleteAllCart,
  deleteCart,
  fetchCarts,
  updateCartQuantity,
} from '../../stores/slices/cartSlice';
import { fetchCouponByName } from '../../stores/slices/couponSlice';
import { createOrder, fetchOrders } from '../../stores/slices/orderSlice';
import { fetchPayments } from '../../stores/slices/paymentSlice';
import { fetchPromotions } from '../../stores/slices/promotionSlice';
import { setToastShow } from '../../stores/slices/toastSlice';
import Button from '../Button';
import IconButton from '../IconButton';
import Input from '../Input';
import Select from '../Select';

function Cart() {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { cartModalShow } = useSelector((state) => state.cartModal);
  const { coupon } = useSelector((state) => state.coupon);
  const { orderLoading } = useSelector((state) => state.order);
  const { promotions } = useSelector((state) => state.promotion);
  const dispatch = useDispatch();
  const [payment, setPayment] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [accordion, setAccordion] = useState(false);
  const [promotion, setPromotion] = useState('');
  const { carts, cartLoading } = useSelector((state) => state.cart);
  const { payments, paymentLoading } = useSelector((state) => state.payment);
  const [paymentOptions, setPaymentOptions] = useState([
    {
      value: '',
      label: 'Choose a payment',
    },
  ]);

  const handlePaymentChange = (e) => {
    if (e.target.value === '') {
      setPaymentError('Please select a payment option');
    } else {
      setPaymentError('');
    }
    setPayment(e.target.value);
  };

  const handleAccordionClick = () => {
    setAccordion(!accordion);
  };

  const handleClickDeleteItem = (id) => {
    dispatch(deleteCart({ id }))
      .unwrap()
      .then(() => {
        dispatch(fetchCarts());
      })
      .catch((err) => {
        dispatch(setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }));
      });
  };

  const handleChangeQuantity = (id, quantity) => {
    dispatch(updateCartQuantity({ id, quantity }))
      .unwrap()
      .then(() => {
        dispatch(fetchCarts());
      })
      .catch((err) => {
        dispatch(setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }));
      });
  };

  const handleClickDeleteAll = () => {
    dispatch(deleteAllCart())
      .unwrap()
      .then(() => {
        dispatch(fetchCarts());
      })
      .catch((err) => {
        dispatch(setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }));
      });
  };

  const totalCart = () => {
    let tempTotal = 0;
    if (carts !== null && carts.length > 0) {
      carts.forEach((x) => {
        tempTotal += x.price;
      });
    }
    if (promotion !== '') {
      const tempPromotion = promotions.find((x) => x.id === promotion);
      if (tempTotal < tempPromotion.minimum_spend) {
        setPromotion('');
      }
    }
    setTotal(tempTotal);
  };

  const countDiscount = () => {
    let tempDiscount = 0;
    if (coupon !== null) {
      tempDiscount += coupon.amount;
    }

    if (promotion !== '') {
      const tempPromotion = promotions.find((x) => x.id === promotion);
      tempDiscount += tempPromotion.amount;
    }

    setDiscount(tempDiscount);
  };

  useEffect(() => {
    if (token) {
      setPaymentError('');
      setPayment('');
      setCouponCode('');
      setCouponError('');
      setAccordion(false);
      setPromotion('');
      setDiscount(0);
      dispatch(fetchPromotions());
      dispatch(fetchPayments())
        .unwrap()
        .then(() => {
          const tempPayment = [
            {
              value: '',
              label: 'Choose a payment',
            },
          ];
          tempPayment.push(...payments.map((x) => ({ value: x.id, label: x.description })));
          setPaymentOptions(tempPayment);
        })
        .catch((err) => {
          dispatch(
            setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
          );
        });
    }
  }, [cartModalShow]);

  useEffect(() => {
    if (token) {
      totalCart();
    }
  }, [carts]);

  const handleCheckoutClick = () => {
    if (payment === '') {
      setPaymentError('Please select a payment option');
    }

    if (payment && !couponError) {
      dispatch(createOrder({ couponCode, promotion, payment }))
        .unwrap()
        .then(() => {
          dispatch(fetchCarts());
          dispatch(
            setToastShow({
              toastShow: true,
              toastIsError: false,
              toastMessage: 'successfully create order',
            }),
          );
          dispatch(setCartModal());
          dispatch(fetchOrders());
          navigate('/histories');
        })
        .catch((err) => {
          dispatch(
            setToastShow({ toastShow: true, toastIsError: true, toastMessage: err.message }),
          );
        });
    }
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    if (e.target.value !== '') {
      dispatch(fetchCouponByName({ name: e.target.value }))
        .unwrap()
        .then(() => {})
        .catch((err) => {
          if (err.message === 'record not found') {
            setCouponError('Coupon not found');
          }
        });
    } else {
      setCouponError('');
    }
  };

  useEffect(() => {
    if (token && coupon !== null) {
      setCouponError('');
    }
    countDiscount();
  }, [coupon, promotion]);

  const handlePromotionClick = (valid, id) => {
    if (valid) {
      setPromotion(id);
    }
  };

  return (
    <>
      <div
        id="drawer-right-example"
        className={`${
          cartModalShow ? 'transform-none ' : 'translate-x-full '
        } fixed z-40 h-screen shadow-md p-4 overflow-y-auto bg-white w-[100vw] md:w-[26rem] transition-transform right-0 top-0 rounded-tl-3xl`}
        tabIndex="-1"
        aria-labelledby="drawer-right-label"
      >
        <h5
          id="drawer-right-label"
          className="inline-flex mr-3 mb-4 text-xl font-semibold text-gray-500"
        >
          Cart
        </h5>
        {carts !== null && carts.length > 0 && (
          <IconButton
            className="bg-red-400 hover:bg-red-600 mt-1"
            icon={trash}
            name="deleteAll"
            handleClick={() => {
              handleClickDeleteAll();
            }}
          />
        )}
        <button
          type="button"
          data-drawer-dismiss="drawer-right-example"
          aria-controls="drawer-right-example"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={() => {
            dispatch(setCartModal());
          }}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        {carts !== null && carts.length > 0 ? (
          <>
            {(cartLoading || paymentLoading) && (
              <div className="w-[100%]">
                <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
              </div>
            )}
            {!cartLoading && !paymentLoading && (
              <div className="max-h-64 overflow-y-scroll">
                {carts.map((x) => {
                  const options = JSON.parse(x.options);
                  return (
                    <div
                      key={x.id}
                      className="w-full p-1 px-2 rounded-lg hover:shadow-md flex items-center justify-between gap-2 cursor-pointer"
                    >
                      <div className="flex items-center gap-2 w-full">
                        <img
                          className="w-20 h-20 max-w-[80px] rounded-lg object-contain"
                          src={x.menu.photo_url}
                          alt="food"
                        />
                        <div className="flex flex-col gap-0">
                          <p className="text-base text-gray-700">{x.menu.name}</p>
                          <p className="text-xs text-gray-400">
                            {options[0].value}
                            {' '}
                            +Rp.
                            {' '}
                            {convertToRupiah(options[0].additional_price)}
                          </p>
                          <p className="text-xs text-gray-400">
                            Note:
                            {' '}
                            {x.note}
                          </p>
                          <p className="text-sm text-gray-400 font-bold">
                            Rp.
                            {' '}
                            {convertToRupiah(x.price)}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-end gap-1">
                        <Button
                          className={`w-fit px-3 py-1 ${
                            x.quantity === 1 ? 'bg-gray-200 hover:bg-gray-200 ' : ''
                          }`}
                          name="decrementButton"
                          handleClick={() => {
                            handleChangeQuantity(x.id, x.quantity - 1);
                          }}
                          isDisabled={x.quantity === 1}
                        >
                          -
                        </Button>
                        {x.quantity}
                        <Button
                          className="w-fit px-2.5 py-[0.22rem] ml-1"
                          name="incrementButton"
                          handleClick={() => {
                            handleChangeQuantity(x.id, x.quantity + 1);
                          }}
                        >
                          +
                        </Button>
                        <Button
                          className="w-fit px-2.5 py-[0.22rem] ml-1 bg-white border border-red-400 text-red-400 hover:bg-white hover:text-red-700 hover:border-red-700"
                          name="incrementButton"
                          handleClick={() => {
                            handleClickDeleteItem(x.id);
                          }}
                        >
                          x
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <Select
              id="payment"
              name="payment"
              value={payment}
              handleChange={handlePaymentChange}
              lists={paymentOptions}
              label="Choose Payment"
              className="mb-2"
              validation={paymentError}
              touched
            />
            <Input
              type="text"
              name="coupon"
              id="coupon"
              placeholder="Coupon"
              label="Coupon code"
              value={couponCode}
              handleInputChange={handleCouponChange}
              touched
              validation={couponError}
            />
            <div id="accordion-collapse" data-accordion="collapse">
              <h2 id="accordion-collapse-heading-1">
                <button
                  type="button"
                  className={`${
                    accordion
                      ? 'border border-b-gray-200 rounded-t-xl '
                      : 'border border-gray-200 rounded-xl '
                  }flex items-center justify-between w-full p-2 font-medium text-left text-sm hover:bg-gray-100 bg-white text-gray-900`}
                  data-accordion-target="#accordion-collapse-body-1"
                  aria-expanded="true"
                  aria-controls="accordion-collapse-body-1"
                  onClick={handleAccordionClick}
                >
                  <span>Promotions</span>
                  <svg
                    data-accordion-icon=""
                    className={`${!accordion ? 'rotate-180 ' : ''}w-6 h-6 shrink-0`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id="accordion-collapse-body-1"
                className={`${accordion ? '' : 'hidden'}`}
                aria-labelledby="accordion-collapse-heading-1"
              >
                {promotions !== null && promotions.length > 0 && (
                  <>
                    {promotions.map((x) => {
                      const valid = Number(total) >= Number(x.minimum_spend);

                      return (
                        <div
                          key={x.id}
                          className={`${valid ? 'grayscale-0 ' : 'grayscale cursor-not-allowed '} ${
                            promotion === x.id ? 'border-green-400 ' : ''
                          }p-5 font-light border border-gray-200 sm:hover:shadow-lg sm:hover:bg-gray-200`}
                          onClick={() => {
                            handlePromotionClick(valid, x.id);
                          }}
                          role="button"
                          onKeyPress={() => {}}
                          tabIndex="0"
                        >
                          <div className="flex items-center gap-2 w-full">
                            <img
                              className="w-20 h-20 max-w-[80px] rounded-lg object-contain"
                              src={x.image_url}
                              alt="promotion"
                            />
                            <div className="flex flex-col gap-0">
                              <p className="text-base text-gray-700">{x.name}</p>
                              <p className="text-xs text-gray-400">{x.description}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <hr className="my-8 h-px bg-gray-200 border-0" />
            <div className="flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="flex">
                  <p>Subtotal:</p>
                </div>
                <div className="flex">
                  <p>
                    {' '}
                    Rp.
                    {' '}
                    {convertToRupiah(total)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex">
                  <p>Discount:</p>
                </div>
                <div className="flex text-red-400">
                  <p>
                    {' '}
                    -Rp.
                    {' '}
                    {convertToRupiah(discount)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row justify-between text-lg">
                <div className="flex font-bold">
                  <p>Total:</p>
                </div>
                <div className="flex font-bold">
                  <p>
                    Rp.
                    {' '}
                    { total - discount < 0 ? 0 : convertToRupiah(total - discount)}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 mb-4 grid-cols-1 bottom-0">
              <Button
                className="w-full ml-1"
                name="incrementButton"
                handleClick={handleCheckoutClick}
                isLoading={cartLoading || orderLoading}
                isDisabled={cartLoading || orderLoading}
              >
                Checkout
              </Button>
            </div>
          </>
        ) : (
          <div className="py-20">
            <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
            <p className="text-center mt-4">No Items</p>
          </div>
        )}
      </div>
      {cartModalShow && (
        <div className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30" />
      )}
    </>
  );
}

export default Cart;
