import React from 'react';
import { Outlet } from 'react-router-dom';
import Cart from '../../components/Cart';
import Navbar from '../../components/Navbar';

function HomeLayout() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Cart />
    </>
  );
}

export default HomeLayout;
