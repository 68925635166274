import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchOrders = createAsyncThunk('order/fetch', () => client
  .get('/orders')
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const createOrder = createAsyncThunk('order/create', (data) => client
  .post(
    'orders',
    JSON.stringify({
      coupon_code: data.couponCode,
      promotion_id: data.promotion,
      payment_option_id: data.payment,
    }),
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  orders: [],
  orderError: null,
  orderLoading: false,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.fulfilled, (state, { payload }) => ({
      ...state,
      orders: payload.data,
      orderLoading: false,
    }));
    builder.addCase(fetchOrders.pending, (state) => ({
      ...state,
      orderError: null,
      orderLoading: true,
    }));
    builder.addCase(fetchOrders.rejected, (state, { error }) => ({
      ...state,
      orderError: error.message,
      orderLoading: false,
    }));
    builder.addCase(createOrder.fulfilled, (state) => ({
      ...state,
      orderLoading: false,
    }));
    builder.addCase(createOrder.pending, (state) => ({
      ...state,
      orderError: null,
      orderLoading: true,
    }));
    builder.addCase(createOrder.rejected, (state, { error }) => ({
      ...state,
      orderError: error.message,
      orderLoading: false,
    }));
  },
});

export default orderSlice.reducer;
