import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchCoupons = createAsyncThunk('coupon/fetch', () => client
  .get('/coupons')
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const fetchCouponByName = createAsyncThunk('coupon/fetchByName', ({ name }) => client
  .get(`/coupons/${name}/search`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  coupons: [],
  coupon: null,
  couponError: null,
  couponLoading: false,
};

export const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCoupons.fulfilled, (state, { payload }) => ({
      ...state,
      coupons: payload.data,
      couponLoading: false,
    }));
    builder.addCase(fetchCoupons.pending, (state) => ({
      ...state,
      couponError: null,
      couponLoading: true,
    }));
    builder.addCase(fetchCoupons.rejected, (state, { error }) => ({
      ...state,
      couponError: error.message,
      couponLoading: false,
    }));
    builder.addCase(fetchCouponByName.fulfilled, (state, { payload }) => ({
      ...state,
      coupon: payload.data,
    }));
    builder.addCase(fetchCouponByName.rejected, (state) => ({
      ...state,
      coupon: null,
    }));
  },
});

export default couponSlice.reducer;
