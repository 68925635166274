import { useEffect, useState } from 'react';

const usePasswordForm = () => {
  const [form, setForm] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  });
  const [touched, setTouched] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });
  const [error, setError] = useState({});

  const validate = () => {
    const errors = {};
    if (!form.old_password) {
      errors.old_password = 'Please input old password';
    }

    if (!form.new_password) {
      errors.new_password = 'Please input new password';
    }

    if (!form.confirm_password) {
      errors.confirm_password = 'Please input confirmation password';
    } else if (form.new_password !== form.confirm_password) {
      errors.confirm_password = 'New password and confirm password must be the same';
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [form]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnBlur = (e) => {
    const { name } = e.target;
    setTouched((prevState) => ({ ...prevState, [name]: true }));
  };

  const validateForm = () => {
    setTouched({
      old_password: true,
      new_password: true,
      confirm_password: true,
    });
    return validate();
  };

  return {
    form,
    error,
    touched,
    handleOnBlur,
    handleFormChange,
    validateForm,
    setForm,
  };
};

export default usePasswordForm;
