import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchPayments = createAsyncThunk('payment/fetch', () => client
  .get('/payments')
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  payments: [],
  paymentError: null,
  paymentLoading: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayments.fulfilled, (state, { payload }) => ({
      ...state,
      payments: payload.data,
      paymentLoading: false,
    }));
    builder.addCase(fetchPayments.pending, (state) => ({
      ...state,
      paymentError: null,
      paymentLoading: true,
    }));
    builder.addCase(fetchPayments.rejected, (state, { error }) => ({
      ...state,
      paymentError: error.message,
      paymentLoading: false,
    }));
  },
});

export default paymentSlice.reducer;
