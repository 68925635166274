import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../assets/svgs/loader-black.svg';
import noData from '../../assets/svgs/no-data.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Select from '../../components/Select';
import convertToRupiah from '../../helpers/convertToRupiah';
import { fetchCategories } from '../../stores/slices/menuCategorySlice';
import { fetchFavorites } from '../../stores/slices/menuSlice';
import DetailModal from './components/DetailModal';

function Favorite() {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('asc');
  const [modal, setModal] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const { favorites, favoritesError, favoritesLoading } = useSelector((state) => state.menu);
  const { categories, categoryError, categoryLoading } = useSelector((state) => state.menuCategory);
  const { token } = useSelector((state) => state.auth);
  const sortOptions = [
    {
      value: 'asc',
      label: 'Ascending',
    },
    {
      value: 'desc',
      label: 'Descending',
    },
  ];

  useEffect(() => {
    dispatch(fetchCategories({ sort: 'asc', sortBy: '', search: '' }));
    dispatch(
      fetchFavorites({
        sort,
        sortBy: 'price',
        search,
        filter: selectedCategory,
      }),
    );
  }, [selectedCategory, search, sort]);

  useEffect(() => {
    if (modal === false) {
      dispatch(
        fetchFavorites({
          sort,
          sortBy: 'price',
          search,
          filter: selectedCategory,
        }),
      );
    }
  }, [modal]);

  const handleSelectCategory = (id) => {
    setSelectedCategory(id);
  };

  const renderCategories = () => {
    const initCategories = [
      {
        id: 'all',
        name: 'All',
      },
    ];

    initCategories.push(...categories);

    return initCategories.map((category) => (
      <Button
        key={category.id}
        type="button"
        name="categoryButton"
        className={`${
          category.id === selectedCategory
            ? 'bg-red-400 text-white '
            : 'text-red-400 border border-red-400 bg-white hover:bg-white hover:text-red-700 hover:border-red-700 '
        } inline-flex w-fit items-center px-5 mt-2 py-2.5 text-sm font-medium `}
        handleClick={() => handleSelectCategory(category.id)}
      >
        {category.name}
      </Button>
    ));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };

  const handleMenuClick = (id) => {
    if (token) {
      setSelectedID(id);
      setModal(true);
    }
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Container>
      {(favoritesLoading || categoryLoading) && (
        <div className="absolute h-screen w-[100%] left-0">
          <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
        </div>
      )}
      {(favoritesError || categoryError) && (
        <div className="h-screen w-[100%] py-20">
          <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
          <p className="text-center mt-4">Something went wrong</p>
        </div>
      )}
      {!favoritesError && !categoryError && (
        <div className="w-full flex flex-col gap-y-2 md:flex-row md:gap-y-0 justify-between mt-28">
          <div className="flex relative w-[100%] md:w-[30%]">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              className="block p-2 pl-10 max-w-7xl w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          <div className="flex">
            <p className="mt-2 mr-2">Sort By Price</p>
            <Select
              id="sort"
              name="sort"
              value={sort}
              handleChange={handleSortChange}
              lists={sortOptions}
            />
          </div>
        </div>
      )}
      {!favoritesLoading && !favoritesError && !categoryLoading && !categoryError && (
        <>
          <div className="w-full mt-2 flex flex-row gap-2 overflow-scroll no-scrollbar sm:justify-start">
            {renderCategories()}
          </div>

          {favorites !== null && favorites.length > 0 ? (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 mt-8 mb-4 content-end">
              {favorites.map((x) => (
                <div
                  key={x.id}
                  className={`${!token ? 'hover:bg-white ' : 'hover:bg-gray-200 '} ${
                    !x.is_available ? 'grayscale cursor-not-allowed ' : 'grayscale-0 '
                  }grid max-w-sm bg-white rounded-lg border border-gray-200 shadow-md`}
                  onClick={() => {
                    if (x.is_available) {
                      handleMenuClick(x.id);
                    }
                  }}
                  role="button"
                  onKeyPress={() => {}}
                  tabIndex="0"
                >
                  <img
                    className="rounded-t-lg mx-auto w-full max-h-32 object-cover"
                    src={x.photo_url}
                    alt={x.name}
                  />
                  <div className="p-5">
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900">{x.name}</h5>
                    {x.menu_category.name !== '' && (
                      <span className="bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded">
                        {x.menu_category.name}
                      </span>
                    )}
                    <p className="mb-3 mt-3 ont-normal text-gray-700">
                      Rp.
                      {' '}
                      {convertToRupiah(x.price)}
                    </p>
                    <div className="flex flex-row gap-1">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-yellow-300 mt-[2px]"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      {Math.round((x.avg_rating + Number.EPSILON) * 100) / 100}
                      <svg
                        className="ml-2 w-5 h-5 text-red-400 mt-[2px]"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {x.number_of_favorites}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="py-20">
              <img className="h-[30vh] mx-auto" src={noData} alt="no data" />
              <p className="text-center mt-4">No Menus</p>
            </div>
          )}
        </>
      )}
      <DetailModal modal={modal} item={selectedID} onCloseModal={handleCloseModal} />
    </Container>
  );
}

export default Favorite;
