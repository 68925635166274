import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
import questions from './game';
import loader from '../../assets/svgs/loader-black.svg';
import { createGame, setGameEnd } from '../../stores/slices/gameSlice';
import convertToRupiah from '../../helpers/convertToRupiah';
import { setToastShow } from '../../stores/slices/toastSlice';

function Game() {
  const interval = useRef();
  const [ctime, setCtime] = useState();
  const dispatch = useDispatch();
  const [question, setQuestion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const navigate = useNavigate();
  const { gameStart, gameEnd } = useSelector((state) => state.game);

  const checkAnswer = (answer = '') => {
    clearInterval(interval.current);
    dispatch(setGameEnd());

    if (answer === '') {
      dispatch(createGame({ is_won: false }))
        .unwrap()
        .then(() => {
          dispatch(
            setToastShow({
              toastShow: true,
              toastIsError: true,
              toastMessage: 'Try again next time',
            }),
          );
          setTimeout(() => {
            navigate('/leaderboard');
          }, 2000);
        });
    } else if (answer === correctAnswer) {
      dispatch(createGame({ is_won: true }))
        .unwrap()
        .then((data) => {
          dispatch(
            setToastShow({
              toastShow: true,
              toastIsError: false,
              toastMessage: `You got ${convertToRupiah(data.data.amount)} voucher`,
            }),
          );
          setTimeout(() => {
            navigate('/leaderboard');
          }, 2000);
        });
    } else {
      dispatch(createGame({ is_won: false }))
        .unwrap()
        .then(() => {
          dispatch(
            setToastShow({
              toastShow: true,
              toastIsError: true,
              toastMessage: 'Try again next time',
            }),
          );
          setTimeout(() => {
            navigate('/leaderboard');
          }, 2000);
        });
    }
  };

  const countdown = (time) => {
    let countTime = time;
    clearInterval(interval.current);

    setCtime(countTime);
    interval.current = setInterval(() => {
      countTime -= 1;
      if (countTime === 0) {
        checkAnswer();
      }
      setCtime(countTime);
    }, 1000);
  };

  useEffect(() => {
    if (gameStart === false) {
      navigate(-1);
    } else {
      const index = Math.floor(Math.random() * (questions.length - 1 - 0 + 1)) + 0;
      setQuestion(questions[index]);
      setCorrectAnswer(questions[index].correctAnswer);
      countdown(10);
    }
  }, []);

  useEffect(() => {
    if (question !== null) {
      setLoading(false);
    }
  }, [question]);

  const setAnswerStyle = (status, isEnd) => {
    if (!isEnd) {
      return 'bg-white hover:bg-gray-100';
    }
    if (status) {
      return 'bg-green-400';
    }
    return 'bg-red-400';
  };

  const handleAnswerClicked = (ans) => {
    if (!gameEnd) {
      checkAnswer(ans);
    }
  };

  return (
    <Container>
      <div className="mt-28">
        {loading && (
          <div className="absolute h-screen w-[100%] left-0">
            <img className="h-[50vh] mx-auto" src={loader} alt="loading" />
          </div>
        )}
        {question != null && !loading && (
          <>
            <div className="relative bg-gray-50 border rounded shadow-xl min-h-[50vh] flex justify-center items-center">
              <p className="text-2xl font-bold text-clip px-4 text-center">{question.question}</p>
              <p className="text-3xl font-bold absolute top-0 text-center">{ctime}</p>
            </div>
            <div className="min-h-[20vh]shadow-xl mt-2">
              <div className="grid grid-cols-2 mt-4 gap-4">
                {question.answers.map((x) => (
                  <div
                    key={x.text}
                    className={`grid bg-gray border rounded-xl p-4 shadow-xl ${setAnswerStyle(
                      x.correct,
                      gameEnd,
                    )}`}
                    onClick={() => {
                      handleAnswerClicked(x.text);
                    }}
                    role="button"
                    onKeyUp={() => {}}
                    tabIndex="0"
                  >
                    {x.text}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export default Game;
