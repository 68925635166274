import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../assets/svgs/loader-black.svg';

function IconButton(props) {
  const {
    className, handleClick, icon, name, children, isLoading, isDisabled,
  } = props;
  return (
    <button
      type="button"
      name={name}
      className={`${className} focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center`}
      onClick={handleClick}
      disabled={isLoading || isDisabled}
    >
      <div className={`${isLoading ? 'block ' : 'hidden '} flex justify-center`}>
        <img className="h-5" src={loader} alt="loader" />
      </div>
      <div className={`${isLoading ? 'hidden ' : 'block '}`}>
        <img src={icon} alt="icon" />
        {children}
      </div>
    </button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  handleClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

IconButton.defaultProps = {
  isLoading: false,
  isDisabled: false,
  className: '',
  children: '',
};

export default IconButton;
