import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Toast from './components/Toast';
import HomeLayout from './layouts/HomeLayout';
import ChangePassword from './pages/ChangePassword';
import Favorite from './pages/Favorite';
import Game from './pages/Game';
import History from './pages/History';
import Home from './pages/Home';
import Leaderboard from './pages/Leaderboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import OwnedCoupon from './pages/OwnedCoupon';
import Profile from './pages/Profile';
import Register from './pages/Register';

function App() {
  return (
    <>
      <Toast />
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/coupons" element={<OwnedCoupon />} />
          <Route path="/histories" element={<History />} />
          <Route path="/favorites" element={<Favorite />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/game" element={<Game />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
