import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../config/client';

export const fetchReviews = createAsyncThunk('review/fetch', ({ id }) => client
  .get(`menus/${id}/reviews`)
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

export const createReview = createAsyncThunk('review/create', (data) => client
  .post(
    'reviews',
    JSON.stringify({
      order_detail_id: data.order_detail_id,
      description: data.description,
      rating: parseInt(data.rating, 10),
    }),
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response.data;
  }));

const initialState = {
  reviews: [],
  reviewError: null,
  reviewLoading: false,
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReviews.fulfilled, (state, { payload }) => ({
      ...state,
      reviews: payload.data,
      reviewLoading: false,
    }));
    builder.addCase(fetchReviews.pending, (state) => ({
      ...state,
      reviewError: null,
      reviewLoading: true,
    }));
    builder.addCase(fetchReviews.rejected, (state, { error }) => ({
      ...state,
      reviewError: error.message,
      reviewLoading: false,
    }));
    builder.addCase(createReview.fulfilled, (state) => ({
      ...state,
      reviewLoading: false,
    }));
    builder.addCase(createReview.pending, (state) => ({
      ...state,
      reviewError: null,
      reviewLoading: true,
    }));
    builder.addCase(createReview.rejected, (state, { error }) => ({
      ...state,
      reviewError: error.message,
      reviewLoading: false,
    }));
  },
});

export default reviewSlice.reducer;
