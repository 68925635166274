import { useEffect, useState } from 'react';

const useReviewForm = () => {
  const [form, setForm] = useState({
    rating: 1,
    hover: 1,
    description: '',
  });
  const [touched, setTouched] = useState({
    rating: false,
    description: false,
  });
  const [error, setError] = useState({});

  const validate = () => {
    const errors = {};

    if (!form.rating) {
      errors.rating = 'Please input your rating';
    } else if (form.rating < 1 || form.rating > 5) {
      errors.rating = 'Rating must be between 1 and 5';
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [form]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnBlur = (e) => {
    const { name } = e.target;
    setTouched((prevState) => ({ ...prevState, [name]: true }));
  };

  const validateForm = () => {
    setTouched({
      rating: true,
      description: true,
    });
    return validate();
  };

  return {
    form,
    error,
    setForm,
    setTouched,
    touched,
    handleOnBlur,
    handleFormChange,
    validateForm,
  };
};

export default useReviewForm;
